import React from 'react';

import Slider from 'react-slick';

import { Link } from 'react-router-dom';

import { Button } from 'semantic-ui-react';

import { Icon } from '@plone/volto/components';

import sliderPNG from './slider-image.png';
import sliderJPG4 from './slider-image2.jpg';
import rightSVG from '@plone/volto/icons/right-key.svg';

import leftSVG from '@plone/volto/icons/left-key.svg';


const NextArrow = ({ className, style, onClick }) => (

  <Button

    className={className}

    style={{ ...style, display: 'block' }}

    onClick={onClick}

  >

    <Icon name={rightSVG} size="70px" color="#fff" />

  </Button>

);


const PrevArrow = ({ className, style, onClick }) => (

  <Button

    className={className}

    style={{ ...style, display: 'block' }}

    onClick={onClick}

  >

    <Icon name={leftSVG} size="70px" color="#fff" />

  </Button>

);


const View = props => {

  return (

    <div

      className="tile view mainslider full-width"

      style={{

        background: `url(${sliderPNG}) center no-repeat`,

      }}

    >

      <Slider

        customPaging={dot => <div />}

        dots={true}

        fade

        dotsClass="slick-dots slick-thumb"

        infinite

        speed={500}

        slidesToShow={4}

        slidesToScroll={4}

        nextArrow={<NextArrow />}

        prevArrow={<PrevArrow />}

        autoplay={true}
        pauseOnHover={true}
        mobileFirst={true}
      >

        <div>

          <div className="slide slide1">

            <h3>Decades of</h3>

            <h1>

              Experience

            </h1>

            <Link to="/experience">Learn about our years in the field</Link>

          </div>

        </div>

        <div>

          <div className="slide slide2">

            <h3>Focused on</h3>

            <h1>
		Safety

            </h1>

            <Link to="/safety">Learn about our saftey practices</Link>

          </div>

        </div>

<div>
<div className="slide slide3">
 <h3>Always performing</h3>
		<h1>Quality Work</h1>
	<Link to="/services">Learn about our results</Link>
</div></div>
<div>
<div className="slide slide4">
<h3>Offering our customers</h3>
<h1>Cost-Effective Rates</h1>
<Link to="/rates">Learn about our services</Link>
</div></div>
      </Slider>

    </div>

  );

};

export default View;
