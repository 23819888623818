import React from 'react';


const Edit = props => {

  return <div>I'm the MainSlider edit component!</div>;

};


export default Edit;
